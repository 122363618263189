export default {
  // Battery List
  "battery.list.searchTable.title": "Battery List",
  "battery.list.batteryNumber": "Battery Number",
  "battery.list.batteryName": "Battery Name",
  "battery.list.imei": "IMEI",
  "battery.list.typeName": "Type Name",
  "battery.list.onlineStatus": "Status",
  "battery.list.agent": "Agent",
  "battery.list.stationName": "Station Name",
  "battery.list.businessStatus": "Business Status",
  "battery.list.currentUser": "Current User",
  "battery.list.currentPhone": "Cell-phone Number",
  "battery.list.currentLocation": "Current Location",
  "battery.list.electricity": "Electric Quantity",
  "battery.list.note": "Remarks",
  "battery.list.deploymentStatus": "Deployment Status",
  "battery.list.trajectory": "Trajectory",
  "battery.list.route-map": "Route Map",
  "battery.list.edit-remark": "Edit Remark",
  "battery.list.edit-status": "Edit Status",
  "battery.list.allow-discharge": "Allowable Discharge",
  "battery.list.no-discharge": "No Discharge",
  "battery.list.permitted-charge": "Permitted Charging",
  "battery.list.no-charge": "No Charging",
  "battery.list.batt-info": "Battery Information",
  "battery.list.real-info": "Real-time Information",
  "battery.list.export-data": "Export Data",
  "battery.list.export-csv-data": "Export Csv Data",
  "battery.list.edit": "Edit",
  "battery.list.unbind": "Unbind",
  "battery.list.online": "Online",
  "battery.list.offline": "Offline",
  "battery.list.idle": "Idle",
  "battery.list.appoi-lock": "Appointment lockout",
  "battery.list.swap-lock": "Battery swapping lock",
  "battery.list.lease": "Lease",
  "battery.list.deployed": "Deployed",
  "battery.list.maintenance": "Maintenance",
  "battery.list.ready": "Ready to Deployment",
  "battery.list.curr-loc": "{cabinetName} - No.{box} cabin",
  "battery.list.weight": "Weight",
  "battery.list.rated-vol": "Rated Voltage",
  "battery.list.batt-brand": "Battery Brand",
  "battery.list.batt-type": "Battery Type",
  "battery.list.rated-cap": "Rated Capacity",
  "battery.list.rated-curr": "Rated Current",
  "battery.list.soft-ver": "Software version",
  "battery.list.hard-ver": "Hardware version",
  "battery.list.comm-type": "Communication Type",
  "battery.list.num-cell": "Number of cells",
  "battery.list.batt-structure": "Battery structure",
  "battery.list.date-prod": "Date of production",
  "battery.list.allow-dischar-desc": "Are you sure to allow discharge?",
  "battery.list.no-dischar-desc": "Are you sure to forbid discharge?",
  "battery.list.allow-char-desc": "Are you sure to allow the charging?",
  "battery.list.no-char-desc": "Are you sure to forbid charging?",
  "battery.list.edit-name-desc": "Please make sure that the battery is not in the process of power change, otherwise it will cause error, modify carefully!",
  "battery.list.unbind-desc": "Are you sure to unbind?",

  // Real Time Info
  "battery.list.total-capa": "Total Capacity",
  "battery.list.surplus-elec": "Surplus electricity",
  "battery.list.gsm-sig": "GSM Signal",
  "battery.list.gps-stars": "Number of GPS positioning stars",
  "battery.list.char-switch": "Charging Switch",
  "battery.list.dischar-switch": "Discharge Switch",
  "battery.list.cycle-time": "Cycle Times",
  "battery.list.curr-curr": "Current current",
  "battery.list.curr-vol": "Current voltage",
  "battery.list.device-log": "Device Log",
  "battery.list.task-rec": "Task Record",
  "battery.list.time": "Time",
  "battery.list.type": "Type",
  "battery.list.content": "Content",
  "battery.list.task-send-time": "Task Sending Time",
  "battery.list.miss-name": "Mission Name",
  "battery.list.miss-mode": "Mission Mode",
  "battery.list.task-status": "Task Status",
  "battery.list.task-time-com": "Task Time Consuming",
  "battery.list.send-instruc": "Send Instructions",
  "battery.list.reply-content": "Reply Content",
  "battery.list.no-signal": "(No Signal)",
  "battery.list.cell-temp": "Cell Temperature",
  "battery.list.bms-temp": "BMS Temperature",
  "battery.list.batt-temp": "Battery Temperature",
  "battery.list.vol-curve": "Voltage Curve",
  "battery.list.curr-curve": "Current Curve",
  "battery.list.module-sig": "Module Signal",
  "battery.list.gps-sig": "GPS Signal",
  "battery.list.cell-vol": "Cell Voltage",
  "battery.list.hour": "{hour}Hour",
  "battery.list.day": "{day}Day",
  "battery.list.refresh": "Refresh",
  "battery.list.time-span": "Time Span",

  // Trajectory
  "battery.list.track-start": "Track starts at",
  "battery.list.track-end": "End of track at",
  "battery.list.place": "place",
  "battery.list.track-point": "Track points",
  "battery.list.useless-location": "Useless Location",
  "battery.list.no-address": "No Address",
  "battery.list.no-trace-point": "No trace point at presence",

  // Common
  "battery.common.confirm": "Confirm",
  "battery.common.tip": "Tip",
  "battery.common.send-success": "Send Successfully",
  "battery.common.success-operation": "Successful operation",
  "battery.common.unbind-failed": "Unbind Failed",
  "battery.common.add": "Add",
  "battery.common.update": "Update",
};
