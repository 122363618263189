export default {
  // Vehicle Order
  "vehicle.order.searchTable.title": "Vehicle Order List",
  "vehicle.order.orderType": "Order Type",
  "vehicle.order.orderTime": "Order Time",
  "vehicle.order.orderNumber": "Order Number",
  "vehicle.order.price": "Price",
  "vehicle.order.deposit": "Deposit",
  "vehicle.order.rent": "Rent",
  "vehicle.order.orderStatus": "Order Status",
  "vehicle.order.userName": "User Name",
  "vehicle.order.userPhone": "Cell-phone",
  "vehicle.order.export": "Export Data",

  // Vehicle Order Detail
  "vehicle.order.detail.vehicleInformation": "Vehicle Information",
  "vehicle.order.detail.vehicleBrand": "Vehicle Brand",
  "vehicle.order.detail.vehicleName": "Vehicle Name",
  "vehicle.order.detail.vcuNumber": "VCU Number",
  "vehicle.order.detail.vehicleType": "Vehicle Type",
  "vehicle.order.detail.onlineStatus": "Online Status",
  "vehicle.order.detail.businessStatus": "Business Status",
  "vehicle.order.detail.ownership": "Ownership",
  "vehicle.order.detail.vct": "Vehicle Compartment Type",
  "vehicle.order.detail.orderInfor": "Order Information",
  "vehicle.order.detail.paymentType": "Payment Type",
  "vehicle.order.detail.consumptionAmount": "Consumption Amount",
  "vehicle.order.detail.orderTime": "Order Time",
  "vehicle.order.detail.authenTime": "Authentication Time",
  "vehicle.order.detail.registrationTime": "Registration Time",
  "vehicle.order.detail.idNumber": "ID Number",
  "vehicle.order.detail.realName": "Real Name",
  "vehicle.order.detail.authenStatus": "Authentication Status",
  "vehicle.order.detail.faceRecognition": "Face Recognition",
  "vehicle.order.detail.orderPlatform": "Order Platform",
  "vehicle.order.detail.comment": "Comment",
  "vehicle.order.detail.essentialInfor": "Essential Information",
  "vehicle.order.detail.nickName": "Nick Name",
  "vehicle.order.detail.gender": "Gender",
  "vehicle.order.detail.userId": "User ID",
  "vehicle.order.detail.idCard": "Registration Number"
};
