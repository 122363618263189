import React, { useCallback } from "react";
import {
  KeyOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Avatar, Menu, Spin } from "antd";
import { history, useModel } from "umi";
import { stringify } from "querystring";
import HeaderDropdown from "../HeaderDropdown";
import styles from "./index.less";
import { outLogin } from "@/services/ant-design-pro/api";
import type { MenuInfo } from "rc-menu/lib/interface";
import userAvatar from "../../../public/userIcon.png";

export type GlobalHeaderRightProps = {
  menu?: boolean;
};

/**
 * 退出登录，并且将当前的 url 保存
 */
const loginOut = async () => {
  await outLogin();
  const { query = {}, pathname } = history.location;
  const { redirect } = query;
  // Note: There may be security issues, please note
  if (window.location.pathname !== "/user/login" && !redirect) {
    history.replace({
      pathname: "/user/login",
      search: stringify({
        redirect: pathname
      })
    });
  }
};

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({ menu }) => {
  const { initialState, setInitialState } = useModel("@@initialState");

  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const { key } = event;
      if (key === "logout") {
        localStorage.removeItem("access_token");
        setInitialState(s => ({ ...s, currentUser: undefined }));
        loginOut();
        return;
      } else if (key === "change-password") {
        history.push(`/change-password`);
        return;
      }
      history.push(`/account/${key}`);
    },
    [setInitialState]
  );

  const loading = (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8
        }}
      />
    </span>
  );

  if (!initialState) {
    return loading;
  }

  const { currentUser } = initialState;

  if (!currentUser || !currentUser.name) {
    return loading;
  }

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="logout">
        <LogoutOutlined />
        Logout
      </Menu.Item>
    </Menu>
  );

  const username = localStorage.getItem("username");

  return (
    <HeaderDropdown placement="bottomRight" overlay={menuHeaderDropdown}>
      <span className={`${styles.action} ${styles.account}`}>
        {/* <Avatar size="small" className={styles.avatar} src={currentUser.avatar} alt="avatar" /> */}
        <Avatar
          size="small"
          className={styles.avatar}
          src={userAvatar}
          alt="avatar"
        />
        <span className={`${styles.name} anticon`}>
          {/* {currentUser.name} */}
          {`Welcome! ${username}`}
        </span>
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
