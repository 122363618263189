export default {
  "vehicle-type.type-name": "Type Name",
  "vehicle-type.brand-name": "Brand Name",
  "vehicle-type.type-model": "Vehicle Type",
  "vehicle-type.compartment-type": "Vehicle Compartment Type",
  "vehicle-type.usage-state": "Usage State",
  "vehicle-type.new": "New Type",
  "vehicle-type.detail": "Detail",
  "vehicle-type.edit": "Edit",
  "vehicle-type.update": "Update",
  "vehicle-type.okay": "Okay",
  "vehicle-type.double-battery": "Double Battery",
  "vehicle-type.single-battery": "Single Battery",
  "vehicle-type.status-update-success": "Status updated successfully",
  "vehicle-type.create": "New Vehicle Type",
  "vehicle-type.type-name.required": "Type Name is required",
  "vehicle-type.brand-name.required": "Brand Name is required",
  "vehicle-type.type-model.required": "Type Model is required",
  "vehicle-type.compartment-type.required": "Compartment Type is required",
  "vehicle-type.product-image": "Product Image",
  "vehicle-type.type-name.invalid":
    "letters, numbers, underline and minus are supported, the length limit is 1～20",
  "vehicle-type.new-success": "New Vehicle Type created successfully",
  "vehicle-type.action": "Action",
  "vehicle-type.edit-success": "Vehicle Type updated successfully"
};
