import type { HeaderViewProps } from '@ant-design/pro-layout/lib/Header';
import classNames from 'classnames';
import { Button, Col, Drawer, Menu, Row, Space } from 'antd';

import HeaderLogo from '../HeaderLogo';
import GlobalHeaderRight from '../RightContent';

const Header = (props: HeaderViewProps) => {
  return (
    <Row
      className={classNames(props.className, 'ant-pro-global-header')}
      justify="space-between"
      align="middle"
    >
      <Col>
        <HeaderLogo {...props} />
      </Col>
      <Col>
        <GlobalHeaderRight />
      </Col>
    </Row>
  );
};

export default Header;
