import { useIntl } from "umi";
import { GithubOutlined } from "@ant-design/icons";
import { DefaultFooter } from "@ant-design/pro-layout";

export default () => {
  const intl = useIntl();
  const defaultMessage = intl.formatMessage({
    // id: 'app.copyright.produced',
    // defaultMessage: '蚂蚁集团体验技术部出品',
    id: "app.oyika.copyright.produced",
    defaultMessage: "Oyika Pte Ltd. All rights reserved."
  });

  const currentYear = new Date().getFullYear();

  return <DefaultFooter copyright={`${currentYear} ${defaultMessage}`} />;
};
