// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/app/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-layout__Layout' */'/app/src/.umi/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/~demos/:uuid",
        "layout": false,
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent})],
        "component": ((props) => dynamic({
          loader: async () => {
            const React = await import('react');
            const { default: getDemoRenderArgs } = await import(/* webpackChunkName: 'dumi_demos' */ '/app/node_modules/@umijs/preset-dumi/lib/plugins/features/demo/getDemoRenderArgs');
            const { default: Previewer } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi-theme-default/es/builtins/Previewer.js');
            const { usePrefersColor, context } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi/theme');

            return props => {
              
      const { demos } = React.useContext(context);
      const [renderArgs, setRenderArgs] = React.useState([]);

      // update render args when props changed
      React.useLayoutEffect(() => {
        setRenderArgs(getDemoRenderArgs(props, demos));
      }, [props.match.params.uuid, props.location.query.wrapper, props.location.query.capture]);

      // for listen prefers-color-schema media change in demo single route
      usePrefersColor();

      switch (renderArgs.length) {
        case 1:
          // render demo directly
          return renderArgs[0];

        case 2:
          // render demo with previewer
          return React.createElement(
            Previewer,
            renderArgs[0],
            renderArgs[1],
          );

        default:
          return `Demo ${props.match.params.uuid} not found :(`;
      }
    
            }
          },
          loading: () => null,
        }))()
      },
      {
        "path": "/_demos/:uuid",
        "redirect": "/~demos/:uuid"
      },
      {
        "__dumiRoot": true,
        "layout": false,
        "path": "/~docs",
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/app/node_modules/dumi-theme-default/es/layout.js'), loading: LoadingComponent})],
        "routes": [
          {
            "path": "/~docs",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'README.md' */'/app/README.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "locale": "en-US",
              "order": null,
              "filePath": "README.md",
              "updatedTime": 1731665791000,
              "slugs": [
                {
                  "depth": 1,
                  "value": "Ant Design Pro",
                  "heading": "ant-design-pro"
                },
                {
                  "depth": 2,
                  "value": "5.0 is out! 🎉🎉🎉",
                  "heading": "50-is-out-"
                },
                {
                  "depth": 2,
                  "value": "Translation Recruitment :loudspeaker:",
                  "heading": "translation-recruitment-loudspeaker"
                },
                {
                  "depth": 2,
                  "value": "Features",
                  "heading": "features"
                },
                {
                  "depth": 2,
                  "value": "Templates",
                  "heading": "templates"
                },
                {
                  "depth": 2,
                  "value": "Usage",
                  "heading": "usage"
                },
                {
                  "depth": 3,
                  "value": "Use bash",
                  "heading": "use-bash"
                },
                {
                  "depth": 3,
                  "value": "Use Gitpod",
                  "heading": "use-gitpod"
                },
                {
                  "depth": 2,
                  "value": "Browsers support",
                  "heading": "browsers-support"
                },
                {
                  "depth": 2,
                  "value": "Contributing",
                  "heading": "contributing"
                }
              ],
              "title": "Ant Design Pro"
            },
            "title": "Ant Design Pro"
          },
          {
            "path": "/~docs/components",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__index.md' */'/app/src/components/index.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "filePath": "src/components/index.md",
              "updatedTime": 1731665791000,
              "title": "业务组件",
              "sidemenu": false,
              "slugs": [
                {
                  "depth": 1,
                  "value": "业务组件",
                  "heading": "业务组件"
                },
                {
                  "depth": 2,
                  "value": "Footer 页脚组件",
                  "heading": "footer-页脚组件"
                },
                {
                  "depth": 2,
                  "value": "HeaderDropdown 头部下拉列表",
                  "heading": "headerdropdown-头部下拉列表"
                },
                {
                  "depth": 2,
                  "value": "HeaderSearch 头部搜索框",
                  "heading": "headersearch-头部搜索框"
                },
                {
                  "depth": 3,
                  "value": "API",
                  "heading": "api"
                },
                {
                  "depth": 2,
                  "value": "NoticeIcon 通知工具",
                  "heading": "noticeicon-通知工具"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon API",
                  "heading": "noticeicon-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon.Tab API",
                  "heading": "noticeicontab-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIconData",
                  "heading": "noticeicondata"
                },
                {
                  "depth": 2,
                  "value": "RightContent",
                  "heading": "rightcontent"
                }
              ],
              "hasPreviewer": true,
              "group": {
                "path": "/~docs/components",
                "title": "Components"
              }
            },
            "title": "业务组件 - ant-design-pro"
          }
        ],
        "title": "ant-design-pro",
        "component": (props) => props.children
      },
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "login",
                "layout": false,
                "path": "/user/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Login__Login' */'/app/src/pages/Login/Login'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/app/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "list.product-center",
        "path": "/product",
        "icon": "profile",
        "routes": [
          {
            "name": "type-management",
            "path": "/product/type",
            "routes": [
              {
                "path": "/product/type/vehicle-list",
                "name": "vehicle-type-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Vehicle__TypeList' */'/app/src/pages/Product/Vehicle/TypeList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/product/type/battery-list",
                "name": "battery-type-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Battery__TypeList' */'/app/src/pages/Product/Battery/TypeList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/product/type/cabinet-list",
                "name": "cabinet-type-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Cabinet__TypeList' */'/app/src/pages/Product/Cabinet/TypeList'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "name": "inventory-management",
            "path": "/product/inventory",
            "routes": [
              {
                "path": "/product/inventory/battery-list",
                "name": "battery-inventory",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Battery__StockList' */'/app/src/pages/Product/Battery/StockList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/product/inventory/battery-list/record",
                "name": "battery-inventory-record",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Battery__StockRecord' */'/app/src/pages/Product/Battery/StockRecord'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/inventory/battery-list/record/detail",
                "name": "battery-inventory-record-detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Battery__StockRecordDetail' */'/app/src/pages/Product/Battery/StockRecordDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/inventory/cabinet-list",
                "name": "cabinet-inventory",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Cabinet__StockList' */'/app/src/pages/Product/Cabinet/StockList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/product/inventory/cabinet-list/record",
                "name": "stock-record",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Cabinet__StockRecord' */'/app/src/pages/Product/Cabinet/StockRecord'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/inventory/cabinet-list/record/detail",
                "name": "stock-record-detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Cabinet__StockRecordDetail' */'/app/src/pages/Product/Cabinet/StockRecordDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/inventory/vehicle-inventory/list",
                "name": "vehicle-inventory",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Vehicle__StockList' */'/app/src/pages/Product/Vehicle/StockList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/product/inventory/vehicle-inventory/record",
                "name": "vehicle-stock-record",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Vehicle__StockRecord' */'/app/src/pages/Product/Vehicle/StockRecord'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/inventory/vehicle-inventory/record/detail",
                "name": "vehicle-stock-record-detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Vehicle__StockRecordDetail' */'/app/src/pages/Product/Vehicle/StockRecordDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          },
          {
            "name": "product-management",
            "path": "/product/product",
            "routes": [
              {
                "path": "/product/product/battery-list",
                "name": "battery-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Battery__List' */'/app/src/pages/Product/Battery/List'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/product/product/battery-list/details/:batteryCode",
                "name": "battery-list-real",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Battery__RealTimeDetail' */'/app/src/pages/Product/Battery/RealTimeDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/product/battery-list/trajectory/:batteryCode",
                "name": "battery-list-trajectory",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Battery__Trajectory' */'/app/src/pages/Product/Battery/Trajectory'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/product/cabinet-list",
                "name": "cabinet-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Cabinet__List' */'/app/src/pages/Product/Cabinet/List'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/product/product/cabinet-list/:cabinetCode",
                "name": "cabinet-list-real",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Cabinet__RealTimeDetail' */'/app/src/pages/Product/Cabinet/RealTimeDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/product/vehicle-list",
                "name": "vehicle-list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Vehicle__List' */'/app/src/pages/Product/Vehicle/List'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/product/product/vehicle-list/vehicle-detail/:vehicleCode",
                "name": "vehicle-detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Vehicle__VehicleDetail' */'/app/src/pages/Product/Vehicle/VehicleDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/product/product/vehicle-list/trajectory/:vehicleCode",
                "name": "vehicle-trajectory",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Product__Vehicle__Trajectory' */'/app/src/pages/Product/Vehicle/Trajectory'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "name": "list.orders",
        "path": "/orders",
        "icon": "table",
        "routes": [
          {
            "name": "order-management",
            "routes": [
              {
                "name": "product-order",
                "path": "/orders/product",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Orders__ProductOrder' */'/app/src/pages/Orders/ProductOrder'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "product-order-detail",
                "path": "/orders/product/detail/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Orders__ProductOrderDetail' */'/app/src/pages/Orders/ProductOrderDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "name": "vehicle-order",
                "path": "/orders/vehicle",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Orders__VehicleOrder' */'/app/src/pages/Orders/VehicleOrder'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "name": "vehicle-order-detail",
                "path": "/orders/vehicle/detail/:id/:type",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Orders__VehicleOrderDetail' */'/app/src/pages/Orders/VehicleOrderDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "name": "list.system",
        "path": "/system",
        "icon": "setting",
        "routes": [
          {
            "name": "audit-log",
            "path": "/system/audit-log",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__System__AuditLog' */'/app/src/pages/System/AuditLog'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/app/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
