import type { ReactNode } from 'react';
import type { HeaderViewProps } from '@ant-design/pro-layout/lib/Header';

import { history, useModel } from 'umi';

import styles from './index.less';
import classNames from 'classnames';

export type HeaderLogoProps = {
  logo?: ReactNode;
  title?: string | false;
};

const HeaderLogo = (props: HeaderViewProps) => {
  const { className, logo, title } = props;
  return (
    <div
      className={classNames(className, styles.headerLogo)}
      onClick={(e) => {
        e.preventDefault();
        history.push('/dashboard');
      }}
    >
      <img src={logo ?? '/pro_icon.svg'} alt="" className={styles.logo} />
    </div>
  );
};

export default HeaderLogo;
