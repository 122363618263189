export default {
  "battery.swap.searchTable.title": "Battery Swap Order",
  "battery.swap.orderTime": "Order Time",
  "battery.swap.orderNumber": "Order Number",
  "battery.swap.userName": "User Name",
  "battery.swap.userPhone": "Cell-phone",
  "battery.swap.orderStatus": "Order Status",
  "battery.swap.paymentAmount": "Payment Amount",
  "battery.swap.paymentType": "Payment Type"
};
