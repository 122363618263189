export default {
  "menu.welcome": "Welcome",
  "menu.more-blocks": "More Blocks",
  "menu.home": "Home",
  "menu.admin": "Admin",
  "menu.admin.sub-page": "Sub-Page",
  "menu.login": "Login",
  "menu.register": "Register",
  "menu.register-result": "Register Result",
  "menu.dashboard": "Dashboard",
  "menu.dashboard.analysis": "Analysis",
  "menu.dashboard.monitor": "Monitor",
  "menu.dashboard.workplace": "Workplace",
  "menu.exception.403": "403",
  "menu.exception.404": "404",
  "menu.exception.500": "500",
  "menu.form": "Form",
  "menu.form.basic-form": "Basic Form",
  "menu.form.step-form": "Step Form",
  "menu.form.step-form.info": "Step Form(write transfer information)",
  "menu.form.step-form.confirm": "Step Form(confirm transfer information)",
  "menu.form.step-form.result": "Step Form(finished)",
  "menu.form.advanced-form": "Advanced Form",
  "menu.list": "List",
  "menu.list.user-list": "Users",
  "menu.list.table-list": "Search Table",
  "menu.list.basic-list": "Basic List",
  "menu.list.card-list": "Card List",
  "menu.list.search-list": "Search List",
  "menu.list.search-list.articles": "Search List(articles)",
  "menu.list.search-list.projects": "Search List(projects)",
  "menu.list.search-list.applications": "Search List(applications)",
  "menu.profile": "Profile",
  "menu.profile.basic": "Basic Profile",
  "menu.profile.advanced": "Advanced Profile",
  "menu.result": "Result",
  "menu.result.success": "Success",
  "menu.result.fail": "Fail",
  "menu.exception": "Exception",
  "menu.exception.not-permission": "403",
  "menu.exception.not-find": "404",
  "menu.exception.server-error": "500",
  "menu.exception.trigger": "Trigger",
  "menu.account": "Account",
  "menu.account.center": "Account Center",
  "menu.account.settings": "Account Settings",
  "menu.account.trigger": "Trigger Error",
  "menu.account.logout": "Logout",
  "menu.editor": "Graphic Editor",
  "menu.editor.flow": "Flow Editor",
  "menu.editor.mind": "Mind Editor",
  "menu.editor.koni": "Koni Editor",
  "menu.Roles": "Roles",
  "menu.payment-log": "Payment Log",
  "menu.payment-transaction": "Payment Transaction",
  "menu.Report": "Report",
  //'menu.Report.payment-popularity': 'Payment Popularity',
  //'menu.Report.profit-missed': 'Profit Missed',
  "menu.payment-popularity": "Payment Popularity",
  "menu.profit-missed": "Profit Missed",
  "menu.Settings": "Settings",
  "menu.Settings.setting-country": "Country",
  "menu.Settings.setting-currency": "Currency",
  "menu.Settings.setting-merchant": "Merchant",
  "menu.Settings.setting-merchant-category": "Merchant Category",
  "menu.Settings.setting-systemconfig": "System Config",
  "menu.Settings.payment-gateway": "Payment Gateway",
  "menu.Settings.payment-config": "Payment Config",
  "menu.Settings.payment-option": "Payment Option",
  "menu.Settings.payment-typeDisplay": "Payment Type Display",
  "menu.Settings.setting-CustomerSupport": "Customer Support",
  "menu.change-password": "Change Password",
  "menu.list.batteries": "Batteries",
  "menu.list.batteries.batteries-list": "Batteries List",
  "menu.list.batteries.battery-inventory": "Battery Inventory",
  "menu.list.batteries.battery-type": "Battery Type",
  "menu.list.orders": "Operation Centre",
  "menu.list.orders.order-management": "Order Management",
  "menu.list.orders.order-management.product-order": "Product Order",
  "menu.list.orders.order-management.product-order-detail":
    "Product Order Detail",
  "menu.list.orders.order-management.vehicle-order": "Vehicle Order",
  "menu.list.orders.order-management.vehicle-order-detail":
    "Vehicle Order Detail",
  "menu.list.orders.order-management.battery-order": "Battery Order",
  "menu.list.orders.order-management.battery-order-detail":
    "Battery Order Detail",
  "menu.list.orders.order-management.swapping-battery-order":
    "Swapping Battery Order",
  "menu.list.orders.order-management.swapping-battery-order-detail":
    "Swapping Battery Order Detail",
  // Menu for Product Center
  "menu.list.product-center": "Product Centre",
  // Inventory Management
  "menu.list.product-center.inventory-management": "Inventory Management",
  "menu.list.product-center.inventory-management.cabinet-inventory":
    "Cabinet Inventory",
  "menu.list.product-center.inventory-management.battery-inventory":
    "Battery Inventory",
  "menu.list.product-center.inventory-management.battery-inventory-record":
    "Stock in and Stock out record",
  "menu.list.product-center.inventory-management.battery-inventory-record-detail":
    "Details",
  "menu.list.product-center.inventory-management.stock-record":
    "Inventory Record",
  "menu.list.product-center.inventory-management.stock-record-detail":
    "Details",
  "menu.list.product-center.inventory-management.vehicle-inventory":
    "Vehicle Inventory",
  "menu.list.product-center.inventory-management.vehicle-stock-record":
    "Vehicle Stock in and Stock out record",
  "menu.list.product-center.inventory-management.vehicle-stock-record-detail":
    "Details",
  // Product Management
  "menu.list.product-center.product-management": "Product Management",
  "menu.list.product-center.product-management.battery-list": "Battery List",
  "menu.list.product-center.product-management.battery-list-real":
    "Battery Real-Time Information",
  "menu.list.product-center.product-management.battery-list-trajectory":
    "Battery Route Map",
  "menu.list.product-center.product-management.cabinet-list": "Cabinet List",
  "menu.list.product-center.product-management.cabinet-list-real":
    "Cabinet Real-Time Information",
  "menu.list.product-center.product-management.vehicle-list": "Vehicle List",
  "menu.list.product-center.product-management.vehicle-detail":
    "Vehicle Detail",
  "menu.list.product-center.product-management.vehicle-trajectory":
    "Vehicle Trajectory",
  // Type Management
  "menu.list.product-center.type-management": "Type Management",
  "menu.list.product-center.type-management.battery-type-list": "Battery Type",
  "menu.list.product-center.type-management.cabinet-type-list": "Cabinet Type",
  "menu.list.product-center.type-management.vehicle-type-list": "Vehicle Type",
  // System Centre
  "menu.list.system": "System Centre",
  "menu.list.system.audit-log": "Audit Log"
};
