// @ts-nocheck

import ProfileOutlined from '@ant-design/icons/ProfileOutlined';
import TableOutlined from '@ant-design/icons/TableOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined'

export default {
  ProfileOutlined,
TableOutlined,
SettingOutlined
}
    