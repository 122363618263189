import component from "./en-US/component";
import globalHeader from "./en-US/globalHeader";
import menu from "./en-US/menu";
import pages from "./en-US/pages";
import pwa from "./en-US/pwa";
import settingDrawer from "./en-US/settingDrawer";
import settings from "./en-US/settings";
import batteryList from "./en-US/battery-list";
import batteryInventory from "./en-US/battery-inventory";
import batteryType from "./en-US/battery-type";
import cabinet from "./en-US/cabinet";
import productOrder from "./en-US/product-order";
import vehicleOrder from "./en-US/vehicle-order";
import cabinetType from "./en-US/cabinet-type";
import batteryOrder from "./en-US/battery-order";
import batterySwap from "./en-US/battery-swap";
import vehicleType from "./en-US/vehicle-type";
import vehicleInventory from "./en-US/vehicle-inventory";
import auditLog from "./en-US/audit-log";
import vehicleList from "./en-US/vehicle-list";

export default {
  "navBar.lang": "Languages",
  "layout.user.link.help": "Help",
  "layout.user.link.privacy": "Privacy",
  "layout.user.link.terms": "Terms",
  "app.copyright.produced": "Produced by Ant Financial Experience Department",
  "app.preview.down.block": "Download this page to your local project",
  "app.welcome.link.fetch-blocks": "Get all block",
  "app.welcome.link.block-list":
    "Quickly build standard, pages based on `block` development",

  //oyika
  "app.oyika.copyright.produced": "Oyika Pte Ltd. All rights reserved.",
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
  ...batteryList,
  ...batteryInventory,
  ...batteryType,
  ...cabinet,
  ...productOrder,
  ...vehicleOrder,
  ...cabinetType,
  ...batteryOrder,
  ...batterySwap,
  ...vehicleType,
  ...vehicleInventory,
  ...auditLog,
  ...vehicleList
};
