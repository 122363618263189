export default {
  // Product Order
  "order.product.searchTable.title": "Product Order List",
  "order.product.orderType": "Order Type",
  "order.product.orderNumber": "Order Number",
  "order.product.productName": "Product Name",
  "order.product.orderStatus": "Order Status",
  "order.product.vehicleType": "Vehicle Type",
  "order.product.period": "Current/Total Period",
  "order.product.renew": "Renew price",
  "order.product.userName": "User Name",
  "order.product.userPhone": "Cell-phone",
  "order.product.startTime": "Product Start Time",
  "order.product.endTime": "Product End Time",
  "order.product.comment": "Comment",
  "order.product.addComment": "Add Comment",
  "order.product.seeDetail": "See Detail",
  "order.product.orderTime": "Order Time",
  // Product Order Detail
  "order.product.detail": "Product Order Detail",
  "order.product.detail.terminate": "Terminate",
  "order.product.detail.orderInfor": "Order Information",
  "order.product.detail.cycleNo": "No. of cycle",
  "order.product.detail.batteryNo": "No. of battery",
  "order.product.detail.swapPerCycleNo": "No. of swap per cycle",
  "order.product.detail.timeLimit": "Time Limit",
  "order.product.detail.timeUnit": "Time Unit",
  "order.product.detail.deposit": "Deposit",
  "order.product.detail.adminFee": "Admin Fee",
  "order.product.detail.renewAmount": "Renew Amount",
  "order.product.detail.oderStartTime": "Order start time",
  "order.product.detail.modifiedTime": "Modified time",
  "order.product.detail.orderPlatform": "Order Platform",

  "order.product.detail.essentialInfor": "Essential Information",
  "order.product.detail.nickName": "Nick Name",
  "order.product.detail.gender": "Gender",
  "order.product.detail.userId": "User ID",
  "order.product.detail.idCard": "Registration Number",
  "order.product.detail.authenStatus": "Authentication Status",
  "order.product.detail.faceRecognition": "Face Recognition",
  "order.product.detail.realName": "Real Name",
  "order.product.detail.idNumber": "ID Number",
  "order.product.detail.registrationTime": "Registration Time",
  "order.product.detail.authenTime": "Authentication Time",

  "order.product.detail.cycle": "Cycle",
  "order.product.detail.cycleOrderNo": "Cycle Order Number",
  "order.product.detail.orderStartTime": "Order Start Time",
  "order.product.detail.orderEndTime": "Order End Time",
  "order.product.detail.paymentAmount": "Payment Amount",
  "order.product.detail.orderStatus": "Order Status",
  "order.product.detail.paymentTxnId": "Payment Txn ID",
  "order.product.detail.operation": "Operation",
  "order.product.detail.paymentType": "Payment Type",
  "order.product.detail.consumptionAmount": "Consumption Amount",
  "order.product.detail.orderTime": "Order Time",
  "order.product.addComment.title": "Add Comment",
  "order.product.addComment.orderNo": "Product Order No",
  "order.product.switchPlan": "Switch Plan",
  "order.product.detail.orderNumber": "Order Number",
  "order.product.detail.productName": "Product Name",
  "order.product.detail.startTime": "Product Start Time",
  "order.product.detail.endTime": "Product End Time",
  "order.product.detail.vehicleType": "Vehicle Type",
  "order.product.detail.orderType": "Order Type",
  "order.product.detail.dateTime": "Date Time",
  "order.product.detail.oldProductName": "Old Product Name",
  "order.product.detail.newProductName": "New Product Name",
  "order.product.detail.oldNoOfBattery": "Old No. of Battery",
  "order.product.detail.newNoOfBattery": "New No. of Battery",
  "order.product.detail.oldNoOfSwapPerCycle": "Old No. of Swap",
  "order.product.detail.newNoOfSwapPerCycle": "New No. of Swap",
  "order.product.detail.oldTimeLimit": "Old Time Limit",
  "order.product.detail.newTimeLimit": "New Time Limit",
  "order.product.detail.oldRenewalPrice": "Old Renewal Price",
  "order.product.detail.newRenewalPrice": "New Renewal Price",
  "order.product.detail.oldAgreement": "Old Agreement",
  "order.product.detail.newAgreement": "New Agreement",
  "order.product.detail.switchPlan": "Switch Plan",
  "order.product.detail.switchPlan.name": "Name of product",
  "order.product.switchPlan.title": "Switch Plan",
  "order.product.detail.description": "Product Description",
  "order.product.bindVehicle.title": "Bind Vehicle",
  "order.product.bindVehicle.vehicleType": "Vehicle Type",
  "order.product.bindVehicle.vehicleNumber": "Vehicle Number",
  "order.product.switchPlan.newAgreement": "New Agreement",
  "order.product.switchPlan.success": "Switch Plan Success",
  "order.product.switchPlan.error": "Switch Plan Error"
};
