export default {
  "logs.date": "Date",
  "logs.user-name": "User Name",
  "logs.phone-number": "Phone Number",
  "logs.activity": "Activity",
  "logs.ip-address": "IP Address",
  "logs.operating-system": "Operating System",
  "logs.browser": "Browser",
  "logs.search": "Search"
};
