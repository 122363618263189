export default {
  // Battery Order
  "battery.order.searchTable.title": "Battery Order List",
  "battery.order.orderNumber": "Order Number",
  "battery.order.orderType": "Order Type",
  "battery.order.orderTime": "Order Time",
  "battery.order.price": "Price",
  "battery.order.deposit": "Deposit",
  "battery.order.rent": "Rent",
  "battery.order.orderStatus": "Order Status",
  "battery.order.userName": "User Name",
  "battery.order.userPhone": "Cell-phone",
  "battery.order.export": "Export Data",
  "battery.order.rentSale": "Rent Sale",

  // Battery Order Detail
  "battery.order.detail.batteryInformation": "Battery Information",
  "battery.order.detail.batteryName": "Battery Name",
  "battery.order.detail.batteryNumber": "Battery Number",
  "battery.order.detail.onlineStatus": "Status",
  "battery.order.detail.electricQuantity": "Electric Quantity",
  "battery.order.detail.batteryBrand": "Battery Brand",
  "battery.order.detail.batteryStatus": "Battery Type",
  "battery.order.detail.essentialInfor": "Essential Information",
  "battery.order.detail.orderInfor": "Order Information",
  "battery.order.detail.authenTime": "Authentication Time",
  "battery.order.detail.registrationTime": "Registration Time",
  "battery.order.detail.idNumber": "ID Number",
  "battery.order.detail.realName": "Real Name",
  "battery.order.detail.authenStatus": "Authentication Status",
  "battery.order.detail.faceRecognition": "Face Recognition",
  "battery.order.detail.orderPlatform": "Order Platform",
  "battery.order.detail.comment": "Comment",
  "battery.order.detail.nickName": "Nick Name",
  "battery.order.detail.gender": "Gender",
  "battery.order.detail.userId": "User ID",
  "battery.order.detail.consumptionAmount": "Consumption Amount",
  "battery.order.detail.paymentType": "Payment Type",
  "battery.order.detail.orderTime": "Order Time",
  "battery.order.detail.idCard": "ID Card",
  "battery.order.detail.batteryType": "Battery Type",
  "battery.order.detail.orderStatus": "Order Status",
  "battery.order.detail.orderNumber": "Order Number",
  "battery.order.detail.orderType": "Order Type"
};
