export default {
  "vehicle-list.common.copy-success": "Copied to clipboard",
  "vehicle-list.common.edit": "Edit",
  "vehicle-list.common.trajectory": "Trajectory",
  "vehicle-list.common.statistics": "Statistics",
  "vehicle-list.common.detail": "Details",
  "vehicle-list.common.remark": "Remark",
  "vehicle-list.common.edit-remark": "Edit Remark",
  "vehicle-list.common.edit-success": "Edit Success",
  "vehicle-list.common.edit-failed": "Edit Failed",
  "vehicle-list.common.edit-status": "Edit Status",
  "vehicle-list.common.unbind": "Unbind",
  "vehicle-list.common.unbind-desc": "Are you sure you want to unbind?",
  "vehicle-list.common.tip": "Tip",
  "vehicle-list.common.unbind-success": "Unbind Success",
  "vehicle-list.export": "Export Data",
  "vehicle-list.vcu-number": "VCU Number",
  "vehicle-list.vehicle-name": "Vehicle Name",
  "vehicle-list.type-name": "Type Name",
  "vehicle-list.online-status": "Online Status",
  "vehicle-list.offline": "Offline",
  "vehicle-list.online": "Online",
  "vehicle-list.agent": "Agent",
  "vehicle-list.bind-user": "Bind User",
  "vehicle-list.phone-number": "Phone Number",
  "vehicle-list.vehicle-status": "Vehicle Status",
  "vehicle-list.status.bound": "Bound",
  "vehicle-list.status.idle": "Idle",
  "vehicle-list.status.expired": "Expired",
  "vehicle-list.station-name": "Station Name",
  "vehicle-list.note": "Remarks",
  "vehicle-list.deployment-status": "Deployment Status",
  "vehicle-list.deployment-status.deployed": "Deployed",
  "vehicle-list.deployment-status.maintenance": "Maintenance",
  "vehicle-list.deployment-status.ready-to-deploy": "Ready to Deploy",
  // detail
  "vehicle-list.detail.vehicle-information": "Vehicle Information",
  "vehicle-list.detail.type-name": "Type Name",
  "vehicle-list.detail.vcu-number": "VCU Number",
  "vehicle-list.detail.vehicle-name": "Vehicle Name",
  "vehicle-list.detail.vehicle-brand": "Vehicle Brand",
  "vehicle-list.detail.vehicle-type": "Vehicle Type",
  "vehicle-list.detail.online-status": "Online Status",
  "vehicle-list.detail.activation-state": "Activation State",
  "vehicle-list.detail.activation-state.enabled": "Activated",
  "vehicle-list.detail.activation-state.disabled": "Not Active",
  "vehicle-list.detail.transaction-mode": "Transaction Mode",
  "vehicle-list.detail.transaction-mode.not-sell": "Unsold",
  "vehicle-list.detail.transaction-mode.sell": "Sold",
  "vehicle-list.detail.acc-state": "ACC State",
  "vehicle-list.detail.acc-state.abnormal": "Abnormal",
  "vehicle-list.detail.acc-state.normal": "Normal",
  "vehicle-list.detail.fault-condition": "Fault Condition",
  "vehicle-list.detail.fault-condition.fault": "Fault",
  "vehicle-list.detail.fault-condition.normal": "Normal",
  "vehicle-list.detail.battery-quantity": "Battery Quantity",
  "vehicle-list.detail.battery-quantity.swap": "{number} Swap",
  "vehicle-list.detail.vehicle-compartment": "Vehicle Compartment Type",
  "vehicle-list.detail.vehicle-compartment.single": "Single Battery",
  "vehicle-list.detail.vehicle-compartment.double": "Double Battery",
  // Battery Information
  "vehicle-list.detail.battery-information": "Battery No. {no}",
  "vehicle-list.detail.battery-name": "Battery Name",
  "vehicle-list.detail.battery-number": "Battery Number",
  "vehicle-list.detail.battery-type": "Battery Type",
  "vehicle-list.detail.electric-quantity": "Electric Quantity",
  // bind user
  "vehicle-list.detail.bind-user": "Bind User",
  "vehicle-list.detail.essentialInfor": "Essential Information",
  "vehicle-list.detail.nickName": "Nick Name",
  "vehicle-list.detail.gender": "Gender",
  "vehicle-list.detail.userId": "User ID",
  "vehicle-list.detail.idCard": "Registration Number",
  "vehicle-list.detail.authenStatus": "Authentication Status",
  "vehicle-list.detail.faceRecognition": "Face Recognition",
  "vehicle-list.detail.realName": "Real Name",
  "vehicle-list.detail.idNumber": "ID Number",
  "vehicle-list.detail.registrationTime": "Registration Time",
  "vehicle-list.detail.authenTime": "Authentication Time",
  "vehicle-list.detail.agent-information": "Agent Information",
  "vehicle-list.detail.cellphone": "Cell-phone number",
  "vehicle-list.detail.agency-province": "Agency Province",
  "vehicle-list.detail.agency-city": "Agency City",
  "vehicle-list.detail.agency-country": "Agency Country"
};
